<template>
  <button class="media-button"
          tabindex="0">
    <div class="media-button-preview" aria-hidden="true">
        <slot name="preview"></slot>
    </div>
    <div class="media-button-overlay"
          tabindex="-1">
        <slot name="icon"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'MediaBtn'
}
</script>

<style lang="scss">
.media-button {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  color: #fff;
  background: transparent;
  border-width: 0;
  font-size: 32px;
  cursor: pointer;
  display: inline-flex;
  margin: 0.5rem;
  .media-button-preview{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 100%;
    overflow: hidden;
    img, video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .white-block{
      width: 100%;
      height: 100%;
      border: 0.5px solid #dcdcdc;
      background-color: #fff;
      border-radius: 100%;
    }
  }
  .media-button-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
    background-color: var(--primary);
    transition: all 0.3s ease-in-out 0s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70%;
    }
    .matterport-icon{
      width: 45%;
    }
  }
  &:hover, &:active {
    outline: none;
  }
  &:hover, &:focus, &:active {
    .media-button-overlay{
      transform: scale(0.5);
    }
  }
}

@media (min-width: 768px) {
  .media-button {
    width: 100px;
    height: 100px;
  }
}
</style>
