<template>
  <div id="map-menu" :class="{'in': menuOpen}" role="presentation" tabindex="-1" aria-label="Map hotspot menu">
    <h1 v-if="settings.showMapHeader">Virtual Campus Experience</h1>
    <button id="open-menu"
            ref="openMenu"
            @click="toggleMenu()"
            tabindex="0"
            type="button"
            role="button"
            aria-label="Open menu">
        <span :class="{'in': menuOpen}"><fa icon="fa-solid fa-xmark" /></span>
        <span :class="{'in': !menuOpen}">menu</span>
    </button>
    <div class="popup-container">
      <div class="pins-categories"
          role="menubar"
          tabindex="-1"
          aria-label="Map hotspot categories"
          @keydown.esc="closeMenu('#open-menu')"
          v-show="menuOpen">
          <button @click="closeMenu('#open-menu')" id="close-menu" tabindex="0" type="button" role="button" aria-label="Close menu">
              <fa icon="fa-solid fa-xmark" />
          </button>
          <div role="menuitem" v-for="layer in data" :key="layer.id">
              <div class="category-item" role="presentation">
                  <p class="m-0 py-2 category-name"
                  :aria-expanded="selectedLayer && selectedLayer.id === layer.id"
                  :aria-controls="`pin-layer-${layer.id}`"
                  role="button"
                  tabindex="0"
                  :id="`pin-layer-heading-${layer.id}`"
                  @click="selectLayer(layer)"
                  @keydown.enter="selectLayer(layer)"
                  >
                  {{ layer.name }}
                  </p>
              </div>
              <div :id="`pin-layer-${layer.id}`"
                  :aria-labelledby="`pin-layer-heading-${layer.id}`"
                  role="menu"
                  v-show="selectedLayer && selectedLayer.id === layer.id">
                  <div class="category-list" :id="`category-list-${layer.id}`" role="presentation">
                      <div role="menuitem"
                          :aria-labelledby="`pin-heading-${layer.id}-${category.id}`"
                          v-for="category in layer.categories"
                          :key="category.id">
                          <div class="category-item" role="presentation">
                              <div class="category-icon" v-if="settings.categoryControls">
                                <div class="category-image"
                                    v-if="category.iconType === 2 && category.icon">
                                    <img :src="category.icon.path" :alt="category.name" />
                                </div>
                                <div class="category-color"
                                    :style="{ backgroundColor: category.iconType === 1 && category.primaryColor ? category.primaryColor : 'var(--primary)' }"
                                    v-else>
                                </div>
                              </div>
                              <p class="category-name"
                              :aria-expanded="selectedCategory && selectedCategory.id === category.id"
                              :aria-controls="`pin-category-${layer.id}-${category.id}`"
                              role="button"
                              tabindex="0"
                              :id="`pin-heading-${layer.id}-${category.id}`"
                              @click="selectCategory(category)"
                              @keydown.enter="selectCategory(category)">
                                  {{ category.name }}
                              </p>
                              <input type="checkbox"
                                  :id="`category-checkbox-${layer.id}-${category.id}`"
                                  :aria-label="`Toggle ${category.name} category on map`"
                                  @click="toggleCategory(category)"
                                  autocomplete="off"
                                  v-if="settings.categoryControls"
                                  :checked="category.checked" />
                          </div>
                          <div :id="`pin-category-${layer.id}-${category.id}`"
                              :aria-labelledby="`pin-heading-${layer.id}-${category.id}`"
                              role="menu"
                              v-show="selectedCategory && selectedCategory.id === category.id">
                              <a href="#"
                              class="pin"
                              role="menuitem"
                              :id="`pin-link-${layer.id}-${ category.id }-${ pin.id }`"
                              @click.prevent="onPinSelected(pin)"
                              v-for="pin in category.pins"
                              :key="pin.id">
                              {{ pin.name }}
                              <fa v-show="$store.state.showMapBookmarks && isSavedBookmark(pin)" icon="fa-solid fa-bookmark" title="Bookmark" />
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="layer-images"
          :class="{'in': menuOpen}"
          v-if="selectedLayer && selectedLayer.images.length > 1"
          role="menu"
          tabindex="-1"
          aria-label="Layer Images">
          <h2 class="m-0 py-2 image-selector"
            :id="`layer-${selectedLayer.id}-image-selector`">
            {{ settings.optionsLabel }}
          </h2>
          <div
              :id="`layer-${selectedLayer.id}-images`"
              :aria-labelledby="`layer-${selectedLayer.id}-image-selector`"
              role="menu">
              <button class="image-name"
                v-for="image in selectedLayer.images"
                :key="image.id"
                @click="selectImage(image)"
                @keydown.enter="selectImage(image)"
                type="button"
                role="menuitem"
                aria-label="Apply image">
                {{ image.alt_text }}
              </button>
          </div>
      </div>
      <button
        v-if="settings.skipToContentEnabled && menuOpen"
        @click="closeMenu('#skip-content')"
        @keydown.prevent.enter="closeMenu('#skip-content')"
        class="back-to-top"
      >
        Back to top
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PinList',
  computed: mapState(['data', 'settings', 'menuOpen', 'selectedLayer', 'selectedCategory', 'bookmarks']),
  methods: {
    closeMenu (element) {
      this.toggleMenu()
      this.$emit('closed-menu', element)
    },
    onPinSelected (pin) {
      if (pin.clickMode === 0 || pin.clickMode === 2) { // Content
        this.selectPin(pin)
      } else if (pin.clickMode === 1) {
        window.location.replace(`${pin.clickLink}?return_url=${window.location.href}`)
      }
    },
    isSavedBookmark (pin) {
      return this.$store.state.showMapBookmarks && this.bookmarks.some(bookmark => bookmark.title === pin.name)
    },
    ...mapMutations(['toggleMenu', 'selectLayer', 'selectImage', 'selectCategory', 'selectPin', 'toggleCategory'])
  },
  watch: {
    menuOpen: function (opened) {
      if (opened) {
        this.$refs.openMenu.focus()
      }
    }
  }
}
</script>

<style lang="scss">
#map-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  background-color: #fff;
  z-index: 1001;
  transition: all 0.3s ease-in-out 0s;
  padding: 20px 20px 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  &.in {
    right: 0;
    left: 0;
  }
  #close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translate(50%, -50%);
    cursor: pointer;
    border: 0;
    background: transparent;
    &:hover, &:active {
      outline: none;
      border: 2px solid var(--primary);
      border-radius: 50%;
    }
  }
  .popup-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  .pins-categories {
    margin-top: 20px;
    .category-list {
      padding-left: 20px;
      div{
        padding-right: 5px;
        .pin {
          color: #000;
          font-family: 'Open Sans', sans-serif;
          font-weight: 400;
          cursor: pointer;
          transition: all 0.2s ease-in-out 0s;
          display: block;
          &:hover, &:active {
            outline: none;
            text-decoration: underline;
          }
        }
        .hidden {
          visibility: hidden;
        }
      }
    }
    .category-item {
      display: flex;
      p {
        color: #333333;
        font-size: 20px;
        font-weight: 900;
        font-family: 'Open Sans', sans-serif;
        cursor: pointer;
        display: inline-block;
        flex: 1;
        padding: 0.5rem 0;
        margin: 0;
        border-bottom: 1px dotted #111111;
        &:hover, &:active {
          outline: none;
          border-radius: 0;
          border-bottom: 1px solid var(--primary);
        }
      }
      .category-icon {
        align-self: center;
        width: 30px;
        .category-color {
          width: 1em;
          height: 1em;
          border: 1px black solid;
          margin-right: 5px;
        }
        .category-image {
          width: 34px;
          height: 40px;
          margin-left: -8px;
          align-items: center;
          display: flex;
          img {
            width: 100%;
            vertical-align: top;
          }
        }
      }
      input[type=checkbox] {
        align-self: center;
        width: 1em;
        height: 1em;

        &:hover, &:active {
          outline: 2px solid var(--primary);
        }
      }
    }
  }
  .back-to-top {
    background: none;
    border: none;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .layer-images {
    margin-top: auto;
    display: none;
    .image-selector {
      color: #333333;
      font-size: 20px;
      font-weight: 900;
      font-family: 'Open Sans', sans-serif;
      padding: 0.5rem 0;
      border: none;
      background: none;
      // &.collapsed:after {
      //   content: "\f054";
      // }
      // &:after {
      //   position: absolute;
      //   right: 0;
      //   content: "\f078";
      //   font-family: 'ForkAwesome';
      //   font-weight: 900;
      // }
    }
    .image-name {
      display: block;
      border: none;
      background: none;
    }
    & > div {
      margin-bottom: 10px;
    }
    &.in {
      display: block;
    }
  }
}
#open-menu {
  background: var(--primary);
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  transform: rotate(270deg);
  position: absolute;
  right: 100%;
  top: calc(35% - 100px);
  padding: 6px 0px;
  width: 95px;
  transform-origin: right bottom;
  z-index: 1000;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  span {
    display: none;
    &.in {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  #map-menu {
    width: 33%;
    &.in {
      left: 67%;
      right: unset;
    }
  }
}
</style>
