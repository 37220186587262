<template>
  <FocusLoop class="pin-popup-container"
       :class="{'matterport-container' : pin.clickMode === 2}"
       role="dialog"
       aria-label="Hotspot information"
       aria-modal="true"
       @keydown.esc="closePopup"
       :isVisible="true"
       v-if="pin">
    <div class="popup-close" role="presentation">
        <!-- <button
              v-if="!$store.state.showMapBookmarks && !isSavedBookmark"
              class="bookmark"
              @click="$root.$emit('togg-poi', bookmark)"
              role="button"
              type="button"
              tabindex="0"
              title="Add to bookmarks"
              aria-label="Add to bookmark">
            <fa icon="fa-solid fa-bookmark" />
        </button> -->
         <button
              v-if="$store.state.selectedContent && mobileView"
              class="bookmark"
              @click="closeContent"
              role="button"
              type="button"
              tabindex="0"
              title="Toggle content"
              aria-label="Toggle content">
            <fa icon="fa-solid fa-arrow-left" />
        </button>
        <button
              v-if="$store.state.showMapBookmarks && !isSavedBookmark"
              class="bookmark"
              @click="$root.$emit('save-poi', bookmark)"
              role="button"
              type="button"
              tabindex="0"
              title="Add to bookmarks"
              aria-label="Add to bookmark">
            <fa icon="fa-solid fa-bookmark" />
        </button>
        <button
              v-if="$store.state.showMapBookmarks && isSavedBookmark"
              class="bookmark"
              @click="$root.$emit('remove-poi', pin.name)"
              role="button"
              type="button"
              tabindex="0"
              title="Remove from bookmarks"
              aria-label="Remove from bookmark">
            <fa icon="fa-solid fa-bookmark-slash" />
        </button>
        <button class="close-btn"
              ref="closeBtn"
              @click="closePopup"
              role="button"
              type="button"
              tabindex="0"
              title="Close popup"
              aria-label="Close hotspot">
            <fa icon="fa-solid fa-xmark" />
        </button>
    </div>
    <div class="pin-popup"
         aria-labelledby="transcriptID"
         tabindex="-1">
        <video-transcript :video="pin.videos[selectedPinVideo]" v-if="showVideoTranscripts && !mobileView"></video-transcript>
        <div class="popup-content" aria-label="Description text" v-else>
            <h3 class="pin-title" :id="`pin-${pin.id}-popup-name`">{{ pin.name }}</h3>
            <div class="pin-description" v-html="pin.description"></div>
        </div>
        <MediaButtonList />
    </div>
    <PinMedia />
  </FocusLoop>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MediaButtonList from './buttons/MediaButtonList'
import VideoTranscript from './content/video/VideoTranscript'
import PinMedia from './content/PinMedia'
import { FocusLoop } from '@vue-a11y/focus-loop'

export default {
  name: 'PinPopup',
  components: {
    MediaButtonList,
    PinMedia,
    FocusLoop,
    VideoTranscript
  },
  data () {
    return {
      previousFocus: null,
      mobileView: window.innerWidth < 768
    }
  },
  computed: {
    bookmark () {
      return {
        title: this.pin.name,
        url: `${window.location.pathname}${window.location.hash}`,
        description: this.pin.description,
        image: this.pin.thumbnail
      }
    },
    isSavedBookmark () { return this.bookmarks.some(bookmark => bookmark.title === this.pin.name) },
    ...mapState({
      pin: state => state.selectedPin,
      selectedPinVideo: state => state.selectedPinVideo,
      showVideoTranscripts: state => state.showVideoTranscripts,
      bookmarks: state => state.bookmarks
    })
  },
  methods: {
    ...mapMutations(['selectPin', 'toggleContent', 'setShowVideoTranscripts', 'selectPinVideo']),
    closePopup () {
      this.selectPin(null)
      this.setShowVideoTranscripts(false)
      this.selectPinVideo(0)
    },
    closeContent () {
      if (this.$store.state.selectedContent=='images') {
        this.toggleContent('images')
      } else if (this.$store.state.selectedContent=='video') {
        this.toggleContent('video')
      } else if (this.$store.state.selectedContent=='panorama') {
        this.toggleContent('panorama')
      } else if (this.$store.state.selectedContent=='3d_model') {
        this.toggleContent('3d_model')
      }
    }
  },
  watch: {
    pin: {
      handler: function (newPin) {
        if (newPin) {
          this.toggleContent(null)
          // Remember focused element and focus the close button
          this.$nextTick(() => {
            if (document.activeElement) {
              this.previousFocus = document.activeElement
              this.$refs.closeBtn.focus()
            }
          })

          // Open some content by default
          if (window.innerWidth >= 768) {
            if (newPin.images.length > 0) {
              this.toggleContent('images')
            } else if (newPin.video) {
              this.toggleContent('video')
            } else if (newPin.panoramas.length > 0) {
              this.toggleContent('panorama')
            } else if (newPin.url3d && newPin.clickMode !== 2) {
              this.toggleContent('3d_model')
            }
          }
          if (newPin.clickMode === 2 && newPin.url3d) {
            this.toggleContent('3d_model')
          }
        } else {
          if (this.previousFocus) {
            this.previousFocus.focus()
            this.previousFocus = null
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.pin-popup-container {
  .pin-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1002;
    transition: all 0.3s ease-in-out 0s;
    padding: 50px 20px 20px 20px;
    overflow: auto;
    .popup-content, .transcripts {
      padding-right: 1rem;
      margin-top: 15px;
      margin-bottom: 6px;
      h3 {
        color: var(--primary);
      }
      .pin-description {
        p {
          margin-bottom: 12px;
        }
        ul {
          list-style-type: disc;
          list-style-position: inside;
          padding-left: 20px;
          margin: 4px;
        }
        li {
          display: list-item;
          list-style-type: disc;
        }
      }
    }
  }
  .popup-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1003;
    display: flex;
    .bookmark {
      background: var(--primary);
      color: #fff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin: 10px 15px;
    }
    .close-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin: 10px 15px;
      background: var(--primary);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-width: 0;
      cursor: pointer;
      &:hover, &:active {
        outline: none;
        background-color: #fff;
        color: var(--primary);
        border: 2px solid var(--primary);
      }
    }
  }
  &.matterport-container {
    .pin-media {
      right: 0;
      padding: 25px;
    }
    .pin-popup {
      display: none;
    }
    .media-iframe {
      height: 100%;
      max-width: unset;
    }
    .matterport-model {
      height: 100%;
    }
    .close-btn {
      background: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      &:hover, &:active {
        outline: none;
        transform: scale(0.95);
        background: transparent;
        color: #fff;
        border-color: #fff;
      }
    }
  }
}

// ticket 1322
@media (max-width: 767px) {
  .pin-popup-container .popup-close {
    top: 20px !important;
  }
  .pin-popup-container .pin-popup {
    margin-top: 16px !important;
    height: calc(100% - 30px);
  }
  .pin-popup-container .pin-popup .popup-content, 
  .pin-popup-container .pin-popup .transcripts {
    overflow-y: auto;
    height: calc(84% - 47px);
  }
}

@media (min-width: 768px) {
  .pin-popup-container {
    .pin-popup {
      padding: 60px 20px 50px 80px;
      width: 33%;
      overflow: unset;
      left: 67%;
      .popup-content, .transcripts  {
        max-height: 100%;
        overflow: auto;
      }
    }
  }
}

// iPhone X and Xs Max
@media only screen
    and (min-device-width: 812px)
    and (max-device-width: 1024px)
    and (min-device-height: 375px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: landscape) {
  .pin-popup-container .pin-popup {
    padding: 60px 20px 20px 40px;
  }
}

// iPhone XR
@media only screen
    and (min-device-width: 896px)
    and (max-device-width: 1024px)
    and (min-device-height: 414px)
    and (-webkit-device-pixel-ratio: 2)
    and (orientation: landscape) {
  .pin-popup-container .pin-popup {
    padding: 60px 20px 20px 40px;
  }
}
</style>
