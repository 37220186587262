<template>
  <div class="media-btn-container text-center">
    <MediaButton v-if="pin.images.length > 0"
              aria-label="View images"
              @click.native="toggleContent('images')">
      <template v-slot:preview>
        <img :src="pin.images[0].path"
             :alt="pin.images[0].alt_text">
      </template>
      <template v-slot:icon>
        <fa icon="fa-solid fa-camera" />
      </template>
    </MediaButton>
    <MediaButton v-if="pin.url3d"
              aria-label="View 3D model"
              @click.native="toggleContent('3d_model')">
      <template v-slot:preview>
        <div class="white-block"></div>
      </template>
      <template v-slot:icon>
        <img class='matterport-icon' :src="require('@/assets/3d-preview.png')" alt="3D Model Icon">
      </template>
    </MediaButton>
    <MediaButton v-if="pin.panoramas.length > 0"
              aria-label="View panoramic image"
              @click.native="toggleContent('panorama')">
      <template v-slot:preview>
        <img :src="pin.panoramas[0].path"
             :alt="pin.panoramas[0].alt_text"
             crossorigin="anonymous">
      </template>
      <template v-slot:icon>
        <img :src="require('@/assets/360icon.png')" alt="Panoramic image icon">
      </template>
    </MediaButton>
    <MediaButton v-if="pin.videos.length > 0"
              aria-label="View video"
              @mouseenter.native="playVideo = true"
              @mouseleave.native="playVideo = false"
              @click.native="toggleContent('video')">
      <template v-slot:preview>
        <video muted loop preload="none" :src="pin.videos[0].url" tabindex="-1" ref="previewVideoPlayer"></video>
      </template>
      <template v-slot:icon>
        <fa icon="fa-solid fa-play" />
      </template>
    </MediaButton>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MediaButton from './MediaButton'

export default {
  name: 'MediaButtonList',
  components: {
    MediaButton
  },
  data () {
    return {
      playVideo: false,
      playPromise: undefined
    }
  },
  computed: mapState({
    pin: state => state.selectedPin
  }),
  methods: mapMutations(['toggleContent']),
  watch: {
    playVideo: function (newValue) {
      const video = this.$refs.previewVideoPlayer

      if (newValue) {
        this.playPromise = video.play()
      } else {
        if (this.playPromise) {
          this.playPromise.then(() => {
            video.pause()
            video.currentTime = 0

            this.playPromise = undefined
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.media-btn-container {
  top: 50%;
  right: 100%;
  margin: auto;
  transition: all 0.2s ease-in-out 0s;
}

@media (min-width: 768px) {
  .media-btn-container {
    position: absolute;
    transform: translate(50%, -50%);
  }
}

// iPhone X and Xs Max
@media only screen
    and (min-device-width: 812px)
    and (max-device-width: 1024px)
    and (min-device-height: 375px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: landscape) {
  .media-button {
    width: 50px;
    height: 50px;
  }
}

// iPhone XR
@media only screen
    and (min-device-width: 896px)
    and (max-device-width: 1024px)
    and (min-device-height: 414px)
    and (-webkit-device-pixel-ratio: 2)
    and (orientation: landscape) {
  .media-button {
    width: 50px;
    height: 50px;
  }
}
</style>
