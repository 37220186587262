<template>
  <component class="pin-media" role="presentation" :is="contentComponent" v-if="selectedContent"></component>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PinMedia',
  computed: {
    ...mapState(['selectedContent']),
    contentComponent () {
      if (this.selectedContent === 'images') {
        return () => import(/* webpackChunkName: "map-images" */ './PinImages')
      } else if (this.selectedContent === 'video') {
        return () => import(/* webpackChunkName: "map-video" */ './PinVideo')
      } else if (this.selectedContent === 'panorama') {
        return () => import(/* webpackChunkName: "map-panorama" */ './PinPanorama')
      } else if (this.selectedContent === '3d_model') {
        return () => import(/* webpackChunkName: "map-3d-model" */ './Pin3dModel')
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.pin-media {
  transition: all 0.2s ease-in-out 0s;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1002;
  overflow: auto;
  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 768px) {
  .pin-media {
    padding: 0 55px;
    right: 33%;
    z-index: 1001;
  }
}

@media only screen
    and (max-height: 415px)
    and (orientation: landscape) {
  .pin-media {
    align-items: baseline;
    &:before {
      position: fixed;
    }
  }
}
</style>
