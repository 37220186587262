<template>
  <div v-show="loading">
    <div id="loading-map" tabindex="-1" role="presentation">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"></path>
        </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loading',
  computed: mapState(['loading'])
}
</script>

<style lang="scss">
@keyframes spiner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#loading-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 3.5em;
  background-color: rgba(221, 221, 221, 0.48);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 80px;
    animation: spiner 1.3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    z-index: 999;
    path{
      fill: var(--primary);
    }
  }
}
</style>
