import Vue from 'vue'
import App from './App.vue'
import store from './store'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faXmark,
  faCamera,
  faPlay,
  faExpand,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faMinus,
  faSpinner,
  faArrowLeft,
  faBookmarkSlash,
  faBookmark
} from '@fortawesome/pro-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(
  faXmark,
  faCamera,
  faPlay,
  faExpand,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faMinus,
  faSpinner,
  faArrowLeft,
  faBookmarkSlash,
  faBookmark
)

/* add font awesome icon component */
Vue.component('fa', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.errorHandler = () => {
  // Empty error handler to temporarily suppress errors
}

function initMaps (data, settings) {
  store.commit('setData', data)
  store.commit('setSettings', settings)

  return new Vue({
    store,
    render: h => h(App)
  }).$mount('#map-app')
}

export {
  initMaps
}
