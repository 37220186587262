<template>
  <div class="transcripts" v-if="video.transcripts.length >= 1">
    <div class="transcript-languages" v-if="video.transcripts.length > 1">
      <img :src="require(`@/assets/lang/${transcript.lang_code}.png`)"
           class="language-flag"
           :class="{'selected' : selectedLanguage === index}"
           v-for="(transcript, index) in video.transcripts"
           :key="transcript.id"
           @click="selectedLanguage = index" />
    </div>
    <div id="transcriptID" class="transcript" v-html="video.transcripts[selectedLanguage].transcript">
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'VideoTranscript',
  props: {
    video: {
      required: true
    }
  },
  data () {
    return {
      selectedLanguage: 0
    }
  },
  methods: {
    ...mapMutations(['selectTranscriptLanguage'])
  }
}
</script>

<style lang="scss">
.transcript-languages {
  padding: 5px;
  display: flex;
  background-color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  .language-flag {
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 0;
    border-radius: 50%;
    background-color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    &:hover, &:focus, &:active {
      transform: scale(1.1);
    }
    &.selected {
      box-shadow: 0 0 5px #000;
      transform: scale(1.1);
    }
    img {
      width: 100%;
    }
  }
}
</style>
