import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: [],
    settings: {},
    menuOpen: false,
    selectedLayer: null,
    selectedImage: null,
    selectedCategory: null,
    selectedPin: null,
    selectedContent: null,
    loading: true,
    showVideoTranscripts: false,
    selectedPinVideo: 0,
    bookmarks: [],
    showMapBookmarks: false
  },
  getters: {
    getPinByName: (state) => (pinName) => {
      for (const layer of state.data) {
        for (const category of layer.categories) {
          for (const pin of category.pins) {
            if (pin.name === pinName) {
              return pin
            }
          }
        }
      }

      return null
    }
  },
  mutations: {
    setBookmarks (state, bookmarks) {
      state.bookmarks = bookmarks
    },
    setShowMapBookmarks (state, value) {
      state.showMapBookmarks = value
    },
    setData (state, data) {
      state.data = data
    },
    setSettings (state, settings) {
      state.settings = settings
    },
    toggleMenu (state) {
      state.menuOpen = !state.menuOpen
    },
    selectLayer (state, layer) {
      if (state.selectedLayer && state.selectedLayer.id === layer.id) {
        state.selectedLayer = null
      } else {
        state.selectedLayer = layer
        // state.selectedImage = layer.images[0]
      }
    },
    selectImage (state, image) {
      if (!state.selectedImage || (state.selectedImage && state.selectedImage.id !== image.id)) {
        state.selectedImage = image
      }
    },
    selectCategory (state, category) {
      if (state.selectedCategory && state.selectedCategory.id === category.id) {
        state.selectedCategory = null
      } else {
        state.selectedCategory = category
      }
    },
    selectPin (state, pin) {
      if (pin) {
        if (window.helpers && window.helpers.trackMapPinClick) {
          window.helpers.trackMapPinClick(pin.name)
        }
        window.location.hash = encodeURIComponent(pin.name)
      } else {
        window.location.hash = ''
      }
      state.selectedPin = pin
    },
    toggleCategory (state, category) {
      category.checked = !category.checked
    },
    toggleContent (state, content) {
      if (content !== 'video') {
        state.showVideoTranscripts = false
      }
      if (state.selectedContent === content) {
        state.selectedContent = null
      } else {
        state.selectedContent = content
      }
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setShowVideoTranscripts (state, show) {
      state.showVideoTranscripts = show
    },
    selectPinVideo (state, index) {
      state.selectedPinVideo = index
    }
  },
  actions: {
    loadPinFromUrl ({ commit, getters }) {
      if (window.location.hash) {
        const pinName = decodeURIComponent(window.location.hash).substring(1)
        const pin = getters.getPinByName(pinName)

        if (pin) {
          commit('selectPin', pin)
        } else {
          window.location.hash = ''
        }
      } else {
        commit('selectPin', null)
      }
    }
  },
  modules: {
  }
})
