<template>
  <div id="map-app">
    <Loading />
    <Map @closed-menu="setFocus"/>
  </div>
</template>

<script>
import Map from './components/Map.vue'
import Loading from './components/Loading'

export default {
  name: 'App',
  components: {
    Map,
    Loading
  },
  methods: {
    setFocus (element) {
      document.querySelector(element).focus()
    }
  }
}
</script>

<style lang="scss">
#map-app {
  height: 100%;
}
</style>
