<template>
<div class="map-container" v-if="data.length">
  <PinList @closed-menu="$emit('closed-menu', $event)"/>
  <PinPopup />
  <MapPane :mapLayers="data"
           :settings="settings"
           :selectedLayer="selectedLayer ? selectedLayer.id : null"
           :selectedImage="selectedImage ? selectedImage.id : null"
           :selectedPin="selectedPin"
           :bookmarks="bookmarks"
           @tiles-loaded="setLoading(false)"
           @select-pin="onPinSelected($event)"
           @select-layer="selectLayer($event)"
           @select-image="selectImage($event)"></MapPane>
</div>
<div class="map-container" v-else>
  <div class="no-data-message">There is no map data to display.</div>
</div>
</template>

<script>
import MapPane from '@studentbridge/maps-lib'
import { mapState, mapMutations, mapActions } from 'vuex'
import PinList from './menu/PinList'
import PinPopup from './popup/PinPopup'

export default {
  name: 'Map',
  components: {
    MapPane,
    PinList,
    PinPopup
  },
  created () {
    this.loadPinFromUrl()

    if (this.data.length === 0) {
      this.setLoading(false)
    }
  },
  computed: mapState(['data', 'settings', 'selectedLayer', 'selectedImage', 'selectedPin', 'bookmarks']),
  methods: {
    ...mapMutations(['selectPin', 'selectLayer', 'selectImage', 'setLoading']),
    ...mapActions(['loadPinFromUrl']),
    onPinSelected (pin) {
      this.$root.$emit('click-pin', pin)
      if (pin.clickMode === 0 || pin.clickMode === 2) { // Content
        this.selectPin(pin)
      } else if (pin.clickMode === 1) {
        window.location.replace(`${pin.clickLink}?return_url=${window.location.href}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .no-data-message {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
